import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { injectable } from "tsyringe";

import ProductWithQuantityRulesEntity from "../entities/ProductWithQuantityRulesEntity";

export type ProductsWithQuantityRulesState = EntityState<
    ProductWithQuantityRulesEntity,
    number
>;

@injectable()
@StoreConfig({ name: "ProductsWithQuantityRulesStore" })
export class ProductsWithQuantityRulesStore extends EntityStore<ProductsWithQuantityRulesState> {
    constructor() {
        super();
    }
}
